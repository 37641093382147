import { DATAHUB_SUBSCRIPTION_URL, DATAHUB_SUBSCRIPTION_PW } from "env"

export async function putDatahubSubscription({ payload, param }) {
  let extra = ""
  if (param?.length > 0) extra = `/${param}`

  const { email, token } = payload

  try {
    const res = await fetch(
      `${DATAHUB_SUBSCRIPTION_URL}${extra}?email=${email}&token=${token}`,
      {
        method: "PUT",
        crossDomain: true,
        headers: {
          Authorization: `Basic ${DATAHUB_SUBSCRIPTION_PW}`,
          "Content-Type": "application/json",
          "User-Agent": "*",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )

    const data = await res.json()

    return { data, statusCode: res.status, redirected: res.redirected }
  } catch (e) {
    throw `Could not update "${param}" endpoint subscription with ${email} and ${token}}: ${e}`
  }
}
