import { useRef, useState } from "react"
import {
  Header,
  Menu,
  NavDropDownButton,
  NavMenuButton,
  PrimaryNav,
  Title,
} from "@trussworks/react-uswds"
import Link from "next/link"
import { useTranslations } from "use-intl"
import { LocaleSwitcher } from "../LocaleSwitcher"
import { useOnClickOutside, useWindowSize } from "hooks"
import { MSAttribution } from "components/MSAttribution"

export function MainMenu() {
  const [expanded, setExpanded] = useState(false) // mobile menu
  const [isOpen, setIsOpen] = useState(false) // sub menu
  const size = useWindowSize()

  const menuRef = useRef()

  useOnClickOutside(menuRef, () => setIsOpen(false))

  const t = useTranslations("Shared.Header.MainMenu")

  const onClick = () => setExpanded((prvExpanded) => !prvExpanded)

  const closeMobileMenu = () => {
    setExpanded(false)
  }

  const closeJobSearchMenu = () => {
    if (size.width > 768) {
      setIsOpen(false)
    } else {
      closeMobileMenu()
    }
  }

  const onToggle = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen)
  }

  const jobSearchMenuItems = [
    <Link href="/jobs" key="find-jobs" onClick={closeJobSearchMenu} passHref>
      {t("job_search")}
    </Link>,
    <Link href="/archive" key="archive" onClick={closeJobSearchMenu} passHref>
      {t("advanced_search")}
    </Link>,
    <Link
      href="/reposted-jobs"
      key="reposted-jobs"
      onClick={closeJobSearchMenu}
      passHref
    >
      {t("reposted_jobs")}
    </Link>,
    <Link
      href="/about#how-to-apply"
      key="how-to-apply"
      onClick={closeJobSearchMenu}
      passHref
    >
      {t("how_to_apply")}
    </Link>,
    <Link href="/feeds" key="feeds" onClick={closeJobSearchMenu} passHref>
      {t("feeds")}
    </Link>,
  ]

  const testItemsMenu = [
    <Link
      href="/"
      key="home"
      className="usa-nav__link"
      onClick={closeMobileMenu}
      passHref
    >
      <span>{t("home")}</span>
    </Link>,
    <>
      <NavDropDownButton
        menuId="jobSearchMenu"
        onToggle={onToggle}
        isOpen={isOpen}
        label={t("find_jobs")}
      />
      <div ref={menuRef}>
        <Menu
          key="one"
          items={jobSearchMenuItems}
          isOpen={isOpen}
          id="jobSearchMenu"
        />
      </div>
    </>,
    <Link
      href="/about"
      key="about"
      className="usa-nav__link"
      onClick={closeMobileMenu}
      passHref
    >
      <span>{t("about")}</span>
    </Link>,
    <Link
      href="/contact"
      key="contact-us"
      className="usa-nav__link"
      onClick={closeMobileMenu}
      passHref
    >
      <span>{t("contact_us")}</span>
    </Link>,
    <LocaleSwitcher key="locale" />,
    <MSAttribution key="msft" tw="mt-8 md:-mt-3" />,
  ]

  return (
    <>
      <div className={`usa-overlay ${expanded ? "is-visible" : ""}`}></div>
      <Header basic={true} className="bg-white border-dolFooterPrimary shadow">
        <div className="usa-nav-container">
          <div className="usa-navbar">
            <div className="usa-logo">
              <Link href="/" passHref>
                <Title style={{ cursor: "pointer" }}>
                  <h1>
                    <span style={{ fontWeight: 900 }}>SeasonalJobs</span>
                    <span style={{ fontWeight: 400 }}>.dol.gov</span>
                  </h1>
                </Title>
              </Link>
            </div>
            <NavMenuButton onClick={onClick} label={t("menu")} />
            <LocaleSwitcher navMenuButton={true} />
          </div>
          <PrimaryNav
            className="items-center"
            aria-label="Primary Navigation"
            items={testItemsMenu}
            mobileExpanded={expanded}
            onToggleMobileNav={onClick}
          />
        </div>
      </Header>
    </>
  )
}
