import { DATAHUB_SEARCH } from "env"
import { appendLocale } from "utils"
import { formatISO, subDays } from "date-fns"

export async function fetchJobsRss({ locale }) {
  try {
    let SELECT = `case_number,job_title${appendLocale(
      locale
    )},job_duties${appendLocale(locale)},accepted_date`

    const FILTER = `active eq true and display eq true and accepted_date gt ${formatISO(
      subDays(new Date(), 20)
    )}`

    const res = await fetch(DATAHUB_SEARCH, {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "User-Agent": "*",
      },
      body: JSON.stringify({
        search: `*`,
        filter: FILTER,
        top: 1000,
        select: SELECT,
      }),
    })

    const data = await res.json()

    if (data.error) {
      throw data.error.message
    }

    return data.value
  } catch (e) {
    throw `Could not fetch RSS feed jobs: ${JSON.stringify(e)}`
  }
}
