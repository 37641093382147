import App from "next/app"
import Router, { useRouter } from "next/router"
import Head from "next/head"
import Script from "next/script"
import { GlobalTemplate } from "components/Templates"
import { NextIntlClientProvider } from "next-intl"
import NProgress from "nprogress" //nprogress module

import "node_modules/uswds/dist/css/uswds.min.css"
import "react-datepicker/dist/react-datepicker.css"

import "../styles/globals.css"
import "../styles/atlas.css"

import { fetchTranslations } from "utils"
import { Provider } from "react-redux"
import { store } from "state/store"
import { SWRConfig } from "swr"
import { useEffect } from "react"
import { GA_TRACKING_ID, pageview } from "utils/googleAnalytics"
Router.events.on("routeChangeStart", () => NProgress.start())
Router.events.on("routeChangeComplete", () => NProgress.done())
Router.events.on("routeChangeError", () => NProgress.done())

function MyApp({ Component, messages, pageProps }) {
  const router = useRouter()
  const { locale } = router // Get the locale from the router
  useEffect(() => {
    const handleRouteChange = (url) => {
      pageview(url)
    }
    router.events.on("routeChangeComplete", handleRouteChange)
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events])

  if ([`/meta`].includes(router.pathname)) return <Component {...pageProps} />

  return (
    <SWRConfig
      value={{
        errorRetryCount: 2,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }}
    >
      <NextIntlClientProvider
        locale={locale}
        timeZone="America/New_York"
        messages={{ ...messages, ...pageProps.messages }}
      >
        <Provider store={store}>
          <Head>
            <link rel="icon" href="/favicon.ico" />

            <meta
              name="viewport"
              content="initial-scale=1.0, width=device-width"
            />

            {/* DAP tag */}
            <script
              async
              language="javascript"
              id="_fed_an_ua_tag"
              src="https://dap.digitalgov.gov/Universal-Federated-Analytics-Min.js?agency=DOL&subagency=SEASONALJOB&yt=true"
            ></script>
          </Head>
          {/* Google Tag Manager */}
          <Script id="google-tag-manager" strategy="afterInteractive">
            {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-MF49WPW');
        `}
          </Script>
          <div>
            <style
              id="antiClickjack"
              dangerouslySetInnerHTML={{
                __html: `body{display:none !important;}`,
              }}
            />
          </div>
          <Script
            id="security"
            type="text/javascript"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
              if (self === top) {
        var antiClickjack = document.getElementById("antiClickjack");
        antiClickjack.parentNode.removeChild(antiClickjack);
    } else {
        top.location = self.location;
    }`,
            }}
          />

          <Script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
          ></Script>
          <Script
            async
            id="ga"
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
            }}
          />
          <GlobalTemplate>
            <Component {...pageProps} />
          </GlobalTemplate>
        </Provider>
      </NextIntlClientProvider>
    </SWRConfig>
  )
}

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext)
  const { locale } = appContext.router
  const sharedTranslations = await fetchTranslations({
    locale: locale || "en",
    title: "Shared",
  })

  return {
    ...appProps,
    messages: {
      ...sharedTranslations,
    },
  }
}

export default MyApp
