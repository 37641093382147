import { DATAHUB_SUBSCRIPTION_URL, DATAHUB_SUBSCRIPTION_PW } from "env"

export async function putDatahubSubscriptionWithBody({ payload, param }) {
  const { email, tokenArray } = payload

  try {
    const res = await fetch(
      //unsubscribe
      `${DATAHUB_SUBSCRIPTION_URL}/${param}?email=${email}`,
      {
        method: "PUT",
        crossDomain: true,
        headers: {
          Authorization: `Basic ${DATAHUB_SUBSCRIPTION_PW}`,
          "Content-Type": "application/json",
          "User-Agent": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(tokenArray),
      }
    )

    const data = await res.json()

    return { data, statusCode: res.status, redirected: res.redirected }
  } catch (e) {
    throw `Could not update subscription with ${email} for ${JSON.stringify(
      tokenArray
    )}: ${e}`
  }
}
