import { DATAHUB_SUBSCRIPTION_URL, DATAHUB_SUBSCRIPTION_PW } from "env"

export async function fetchOneDatahubSubscription({ id }) {
  try {
    const res = await fetch(`${DATAHUB_SUBSCRIPTION_URL}/${id}`, {
      method: "GET",
      crossDomain: true,
      headers: {
        Authorization: `Basic ${DATAHUB_SUBSCRIPTION_PW}`,
        "Content-Type": "application/json",
        "User-Agent": "*",
      },
    })

    const data = await res.json()

    // if (data.errors) {
    //   throw data.errors
    // }

    return { data, statusCode: res.status, redirected: res.redirected }
  } catch (e) {
    throw `Could not fetch subscriptions with ${email} and ${token}}: ${e}`
  }
}
