import { DATAHUB_SUBSCRIPTION_URL, DATAHUB_SUBSCRIPTION_PW } from "env"

export async function postDatahubSubscription({ payload, param }) {
  let extra = ""
  if (param?.length > 0) extra = `/${param}`
  try {
    const res = await fetch(`${DATAHUB_SUBSCRIPTION_URL}${extra}`, {
      method: "POST",
      crossDomain: true,
      headers: {
        Authorization: `Basic ${DATAHUB_SUBSCRIPTION_PW}`,
        "Content-Type": "application/json",
        "User-Agent": "*",
      },
      body: JSON.stringify(payload),
    })

    const data = await res.json()

    // if (data.errors) {
    //   throw data.errors
    // }

    return data
  } catch (e) {
    throw `Could not create a subscription with data: ${JSON.stringify(
      payload
    )}: ${e}`
  }
}
