import { DATAHUB_SUBSCRIPTION_URL, DATAHUB_SUBSCRIPTION_PW } from "env"

export async function fetchAllDatahubSubscriptions({ payload }) {
  const { email, token } = payload
  try {
    const res = await fetch(
      `${DATAHUB_SUBSCRIPTION_URL}/all?email=${email}&token=${token}`,
      {
        method: "GET",
        crossDomain: true,
        headers: {
          Authorization: `Basic ${DATAHUB_SUBSCRIPTION_PW}`,
          "Content-Type": "application/json",
          "User-Agent": "*",
        },
      }
    )

    const data = await res.json()

    // if (data.errors) {
    //   throw data.errors
    // }

    return { data, statusCode: res.status, redirected: res.redirected }
  } catch (e) {
    throw `Could not fetch subscriptions with ${email} and ${token}}: ${e}`
  }
}
